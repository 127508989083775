import React, { useState } from 'react';
import { useAuth } from '../../context/auth.context';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-light-svg-icons";
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { updatePassword, setLoading, updateSettings } from '../../actions/auth.action';
import i18n from '../../i18n';
import { randomId } from '../../services/utils';
import { Link, useNavigate } from "react-router-dom";
import apiService from '../../services/api';
import { LoginMode } from '../../enums/auth.enum';
import { login } from '../../services/auth.service';
import {ISettings} from '../../interfaces/auth.interface.js';

const PasswordStepComponent: React.FC = () => {
  const api = new apiService();
  const navigate = useNavigate();
  const { t } = i18n;
  const { email, password, settings, loading, dispatch } = useAuth();
  const [formValidated, setFormValidated] = useState(false);

  const handleAuthentication = async (authType: LoginMode) => {
    try {
      dispatch(setLoading(true));
      dispatch(updateSettings({ ...settings, error: '' }));

      const resp = await api.validateCredentials({ email, password: password || '' });

      if (resp.status === 200) {
        dispatch(updateSettings(resp.data as ISettings))

        if (!settings.emailVerified) {
          navigate("/auth/emailVerification");
          return;
        }

        if (authType === LoginMode.BASIC) {
          dispatch(updateSettings({ ...settings, globalLoadingState: true}))
          await login(email, password!);
        }
      } else {
        throw new Error();
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || t('_system_error');
      dispatch(updateSettings({ ...settings, error: errorMessage, globalLoadingState: false }));
    } finally {
      dispatch(setLoading(false));
    }
  };


  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    setFormValidated(true);

    const form = event.currentTarget;

    if (form.checkValidity()) {
      await handleAuthentication(settings.loginMode!)
    } else {
      event.stopPropagation();
    }
  };

  return (
    <Form noValidate validated={formValidated} onSubmit={handleSubmit}>
      <InputGroup>
        <InputGroup.Text><FontAwesomeIcon icon={faLock} /></InputGroup.Text>
        <Form.Control
          placeholder={t('password')}
          aria-label="password"
          required
          type="password"
          onChange={(e) => dispatch(updatePassword(e.target.value))}
          id={randomId()}
          disabled={loading}
          readOnly={loading}
          autoFocus
        />
        <Form.Control.Feedback type="invalid">
          {password ? `${t('invalid_field', { field: t('password') })}` : t('field_cannot_be_blank', { field: t('password') })}
        </Form.Control.Feedback>
      </InputGroup>

      {settings.emailVerified && !loading && (
        <div className='text-end help-link'>
          <Link to="/auth/forgot" className='text-decoration-none'>{t('forgot_password')}</Link>
        </div>
      )}

      <Button variant="primary" type="submit" disabled={loading} className="mt-3 fixed-width-submit-button">
        <div className="notranslate d-flex align-items-center justify-content-center" translate="no">
          {loading && <FontAwesomeIcon icon={faSpinner} className="fa-spin me-2" />}
          {t('continue')}
        </div>
      </Button>
    </Form>
  );
};

export default PasswordStepComponent;
