import i18n from "../i18n";
import { useAuth } from "../context/auth.context";
import { Card } from "react-bootstrap";
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useDocument from '../hooks/useDocument.hooks'

function ForgotPasswordConfirmationPage() {

    const navigate = useNavigate();
    const { t } = i18n;
    const { email } = useAuth();

    useDocument({title: t('_page_title.forgot_password')})

    useEffect(() => {
        if (!email || email === '') {
            navigate("/auth/forgot");
        }
    }, [email, navigate])


    return (
        <>
            <Card.Body>
                <Card.Title className="text-start"><h1>{t('_forgot_password_confirmation.title')}</h1></Card.Title>
                <Card.Text className="text-start">
                    <Trans i18nKey="_forgot_password_confirmation.description" components={{ bold: <strong /> }} values={{ email: email }} />
                </Card.Text>
                <div className='text-end help-link'><Link to="/auth/" className='text-decoration-none'>{t('try_a_different_email')}</Link></div>
            </Card.Body>
        </>
    )
}


export default ForgotPasswordConfirmationPage