import React from 'react';
import i18n from "../../i18n";
import { useAuth } from "../../context/auth.context";
import { Button, Card, } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import config from '../../config';

const ResetPasswordSuccessComponent: React.FC = () => {

  const { t, language } = i18n;
  const { loading } = useAuth();

  return (
    <>
      <Card.Body>
        <Card.Title className="text-start"><h1>{t('_reset_password.title')}</h1></Card.Title>
        <Card.Title className="text-start"><h2>{t('_reset_password.success')}</h2></Card.Title>
        <a rel="noreferrer" href={`${config.CIGO_URL}/?lang=${language}`}>
          <Button variant="primary" type="submit" disabled={loading}>
            {loading && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}{t('continue')}
          </Button>
        </a>
      </Card.Body>
    </>
  );
};

export default ResetPasswordSuccessComponent;
