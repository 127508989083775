import React, { useEffect } from 'react';

const StatusPageScriptComponent: React.FC = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://status.cigotracker.com/embed/script.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return null;
};

export default StatusPageScriptComponent;
