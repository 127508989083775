import apiService from './api';

const api = new apiService();

export const login = async (email: string, password: string, code?: string, rememberDevice: boolean = false) => {
  try {
    const resp = await api.login({ email, password: password || '', code: code || '', remember_device: rememberDevice });
    if (resp.status === 200) {
      window.location.reload();
    } else {
      throw new Error('Login failed');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};
