import { Card } from "react-bootstrap";
import i18n from "../i18n"
import useDocument from '../hooks/useDocument.hooks'

function ErrorPage() {

    const { t } = i18n;

    useDocument({title: t('_page_title.error')})

    return (
        <>
            <Card.Body>
                <Card.Title className="text-start"><h1>{t('_system_error')}</h1></Card.Title>
            </Card.Body>
        </>
    )
}

export default ErrorPage