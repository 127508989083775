import React, { useEffect } from 'react';
import { useAuth } from "../context/auth.context";
import { useNavigate } from "react-router-dom";
import config from '../config';
import { updateSettings } from '../actions/auth.action'
import { ISettings } from '../interfaces/auth.interface';

const FailedAttemptsComponent: React.FC = () => {

  const navigate = useNavigate();
  const { settings, dispatch } = useAuth();

  useEffect(() => {
    if (settings.totalFailedAttempts && settings.totalFailedAttempts > config.MAX_FAILED_ATTEMPTS) {
      dispatch(updateSettings({} as ISettings))
      navigate("/auth/error");
    }
  }, [dispatch, settings.totalFailedAttempts, navigate])

  return (<></>);
};

export default FailedAttemptsComponent;
