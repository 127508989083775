import { Card } from "react-bootstrap"
import i18n from "../i18n"
import { useEffect } from "react";
import config from "../config";
import useDocument from '../hooks/useDocument.hooks'

function LogoutPage() {

    const { t } = i18n;

    useDocument({title: t('_page_title.logout')})


    useEffect(() => {
        window.location.href = config.CIGO_URL + '/user/logout';
    })

    return (
        <>
            <Card.Body>
                <Card.Title className="text-start"><h1>{t('_logout.title')}</h1></Card.Title>
                <Card.Text className="text-start">{t('_logout.description')}</Card.Text>
            </Card.Body>
        </>
    )
}

export default LogoutPage