import React from 'react';
import i18n from "../../i18n";
import { useAuth } from "../../context/auth.context";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const ResetPasswordErrorComponent: React.FC = () => {

  const { t } = i18n;
  const { settings } = useAuth();

  return (
    <>
      <Card.Body>
        <Card.Title className="text-start"><h1>{t('_reset_password.title')}</h1></Card.Title>
        <Card.Text className="text-start">{settings.error || t('invalid')}</Card.Text>
        <div className='text-end help-link'><Link to={"/auth/"} className='text-decoration-none'>{t('try_resetting_passord')}</Link></div>
      </Card.Body>
    </>
  );
};

export default ResetPasswordErrorComponent;
