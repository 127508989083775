import { Card } from "react-bootstrap"
import i18n from "../i18n"
import EmailStepComponent from '../components/loginSteps/emailStep.component'
import PasswordStepComponent from '../components/loginSteps/passwordStep.component'
import MfaStepComponent from '../components/loginSteps/mfaStep.component'
import useDocument from '../hooks/useDocument.hooks'
import { useAuth } from "../context/auth.context";
import { LoginMode } from "../enums/auth.enum";
import LoadingComponent from "../components/loading.component";

function LoginPage() {

    const { t } = i18n;
    const { settings } = useAuth();

    useDocument({ title: t('_page_title.login') })

    return (
        <>
            <Card.Body>
                <Card.Title className="text-start"><h1>{t('_sign_in.title')}</h1></Card.Title>
                <Card.Text className="text-start">{t('_sign_in.description')}</Card.Text>

                {!settings.emailConfirmed && <EmailStepComponent />}
                {settings.emailConfirmed && !settings.passwordConfirmed && <><EmailStepComponent /> <PasswordStepComponent /></>}
                {settings.emailConfirmed && settings.passwordConfirmed && !settings.globalLoadingState && settings.loginMode === LoginMode.MFA && <MfaStepComponent />}
                {settings.emailConfirmed && settings.passwordConfirmed && settings.globalLoadingState && <LoadingComponent />}

            </Card.Body>
        </>
    )
}

export default LoginPage