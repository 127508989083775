import { useCookies } from 'react-cookie';
import config from '../config'
import { CookieNames } from '../enums/auth.enum'

const useCustomCookies = () => {

    const [cookies, setCookie, removeCookie] = useCookies([CookieNames.CIGO_SESSION, CookieNames.HOME_PATH, CookieNames.CIGO_SESSION_PREFIX, CookieNames.LANG]);

    const setCustomCookies = (name: CookieNames, value: string) => {
        setCookie(name, value, {
            sameSite: 'lax',
            secure: true,
            path: '/',
            maxAge: 3600 * 24 * 7,
            domain: config.CIGO_DOMAIN
        });
    };

    const setAuthCookies = (data: any) => {
        removeCookie(CookieNames.CIGO_SESSION, {
            path: '/',
            domain: config.CIGO_DOMAIN
        })

        setCustomCookies(CookieNames.CIGO_SESSION, data.session_uid)
        setCustomCookies(CookieNames.CIGO_SESSION_PREFIX, data.state_prefix)
        setCustomCookies(data.state_prefix, data.state_value)

        if (data.path) {
            setCustomCookies(CookieNames.HOME_PATH, data.path)
        }
    }

    const deleteAuthCookies = () => {
        if (cookies[CookieNames.CIGO_SESSION_PREFIX] && cookies[CookieNames.CIGO_SESSION_PREFIX] !== '') {
            removeCookie(cookies[CookieNames.CIGO_SESSION_PREFIX], {
                path: '/',
                domain: config.CIGO_DOMAIN
            })
        }

        const cookieNames: CookieNames[] = [CookieNames.CIGO_SESSION, CookieNames.HOME_PATH, CookieNames.CIGO_SESSION_PREFIX]
        cookieNames.map((cookie: CookieNames) => {
            return removeCookie(cookie, {
                path: '/',
                domain: config.CIGO_DOMAIN
            })
        })
    }

    const getLoggedInUserPath = () => {
        if (cookies[CookieNames.CIGO_SESSION] && cookies[CookieNames.CIGO_SESSION_PREFIX]) {
            let userPageUrl = config.CIGO_URL

            if (cookies[CookieNames.HOME_PATH] && cookies[CookieNames.HOME_PATH] !== '') {
                userPageUrl += `/${cookies[CookieNames.HOME_PATH]}`
            }
            return userPageUrl;
        }
        return;

    }

    return { setAuthCookies, deleteAuthCookies, getLoggedInUserPath, setCustomCookies, removeCookie };
};

export default useCustomCookies;
