const config = {
    ENV_NAME: process.env.ENV_NAME || 'dev',
    PORT: process.env.PORT ? parseInt(process.env.PORT) : 3000,
    CIGO_DOMAIN: ``,
    API_URL: ``,
    CIGO_URL: ``,
    MAX_FAILED_ATTEMPTS: process.env.REACT_APP_MAX_FAILED_ATTEMPTS ? parseInt(process.env.REACT_APP_MAX_FAILED_ATTEMPTS) : 10,
    LANDING_PAGE_URL: 'https://cigotracker.com'

}

config.CIGO_DOMAIN = window.location.hostname.replace('auth.', '') 
config.API_URL= `https://${config.CIGO_DOMAIN}/api/auth`;
config.CIGO_URL= `https://${config.CIGO_DOMAIN}`;

export default config