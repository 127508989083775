export enum LoginMode {
    BASIC = 'basic',
    MFA = 'mfa'
}

export enum Language {
    ENGLISH = 'en',
    FRENCH = 'fr',
    SPANISH = 'es'
}

export enum CookieNames {
    CIGO_SESSION = 'cigo_session', 
    HOME_PATH = 'user_home_path', 
    CIGO_SESSION_PREFIX = 'cigo_session_prefix',
    LANG = '_lang'
}