import React from 'react';
import logo from '../logo.svg';
import { Alert, Card, Image } from 'react-bootstrap';
import { useAuth } from '../context/auth.context';
import i18n from '../i18n';


const HeaderComponent: React.FC = () => {

  const { settings } = useAuth();
  const { t } = i18n;

  return (
    <>
      <Card.Header>
        {settings.error &&
          <Alert key={'danger'} variant={'danger'}>
            {settings.error}
          </Alert>
        }

        {settings.logoutSuccess &&
          <Alert key={'success'} variant={'success'}>
            {t('_logout_successful')}
          </Alert>
        }

        <Image src={logo} alt="logo" style={{ maxWidth: '165px', margin: '20px 0' }} />
      </Card.Header>
    </>
  );
};

export default HeaderComponent;
