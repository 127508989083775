import { useEffect, useState } from 'react';
import i18n from "../i18n";
import { useAuth } from "../context/auth.context";
import { updateSettings } from '../actions/auth.action'
import { getResetToken } from '../services/utils';
import { ISettings } from '../interfaces/auth.interface';
import ResetPasswordErrorComponent from "../components/resetPassword/error.component";
import ResetPasswordSuccessComponent from "../components/resetPassword/success.component";
import ResetPasswordFormComponent from "../components/resetPassword/form.component"
import LoadingComponent from "../components/loading.component"
import useDocument from '../hooks/useDocument.hooks'


function ResetPasswordPage() {

    const { t } = i18n;
    useDocument({title: t('_page_title.reset')})

    const { settings, dispatch } = useAuth();
    const [initialLoading, setInitialLoading] = useState(true)

    useEffect(() => {

        const token = getResetToken();
        const result = { validResetToken: false, error: '' } as ISettings

        if (!token || token === '') {
            result.error = t('_reset_password.missing_token')
            setInitialLoading(false);
            dispatch(updateSettings(result));
        } else {
            const validateToken = async () => {
                result.validResetToken = true
                setInitialLoading(false);
                dispatch(updateSettings(result));
            }
            validateToken()
        }

    }, [dispatch, t])


    if (initialLoading) {
        return (
            <LoadingComponent />
        )
    }

    if (!settings.validResetToken) {
        return (
            <ResetPasswordErrorComponent />
        )
    }

    if (settings.resetSuccess) {
        return (
            <ResetPasswordSuccessComponent />
        )
    }

    return (
        <ResetPasswordFormComponent />
    )

}


export default ResetPasswordPage