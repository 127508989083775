import i18n from "../i18n";
import {useAuth} from "../context/auth.context";
import {Button, Card} from "react-bootstrap";
import {Trans} from 'react-i18next';
import {Link, useNavigate} from "react-router-dom";
import useDocument from '../hooks/useDocument.hooks';
import {useCallback, useEffect, useState} from "react";
import apiService from '../services/api';
import {encodeBase64} from '../services/utils'

function VerifyEmailAdvicePage() {
  const navigate = useNavigate();
  const {t} = i18n;
  const {email, settings} = useAuth();

  const [encodedEmail,setEncodedEmail] = useState('');

  useDocument({title: t('_page_title.verify_email_advice')});

  const sendVerificationLinkEmail = useCallback(async () => {
    const api = new apiService();

    if (settings.emailConfirmed) {
      await api.sendEmailVerificationLink({email});
    }
  }, [email, settings.emailConfirmed]);

  useEffect(() => {
    if (!email || email === '') {
      navigate("/auth/");
    } else {
      sendVerificationLinkEmail();
      setEncodedEmail(encodeBase64(email))
    }
  }, [email, navigate, sendVerificationLinkEmail]);

  return (
    <>
      <Card.Body>
        <Card.Title className="text-start"><h1 className="mb-4" >{t('verify_email_advice.title')}</h1></Card.Title>
        <Card.Text className="text-start">
          <Trans i18nKey="verify_email_advice.description" components={{bold: <strong />}} values={{email: email}} />
          <br />
        </Card.Text>
        <Card.Text className="text-start">
          <small>
            <Trans i18nKey="verify_email_advice.extra_description" />
          </small>
        </Card.Text>
        <Card.Footer className="d-flex justify-content-end align-items-center">
          <div className='text-end help-link mx-2'>
            <Link to="/auth/login" className='text-decoration-none' reloadDocument>
              {t('verify_email_advice.back_home')}
            </Link>
          </div>
          <Button
            variant="primary"
            type="button"
            href={"/auth/login?un=" + encodedEmail}
          >
            {t('verify_email_advice.email_already_verified')}
          </Button>
        </Card.Footer>
      </Card.Body>
    </>
  );
}

export default VerifyEmailAdvicePage;