import React from 'react';
import { CookieNames, Language } from '../enums/auth.enum'
import i18n from '../i18n';
import useCustomCookies from '../hooks/useCustomCookies.hooks';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';

const languages = [
  Language.ENGLISH,
  Language.FRENCH,
  Language.SPANISH
]

const LanguageSwitcherComponent: React.FC = () => {

  const currentLang = i18n.language
  const { setCustomCookies } = useCustomCookies()

  const changeLanguage = (newLang: Language) => {
    i18n.changeLanguage(newLang).then(() => { }).catch((e: any) => { console.error(e) });
    setCustomCookies(CookieNames.LANG, newLang)
  }

  return (
    <>


      <div className='languageSwitcherContainer'>


        <FontAwesomeIcon icon={faGlobe} className="" />

        <Form.Select
          size="sm"
          onChange={e => changeLanguage(e.target.value as Language)}
          value={currentLang}
        >


          {languages.map((lang: Language, index: number) => {
            return (
              <option value={lang} key={index}>
                {lang.toUpperCase()}
              </option>
            )
          })}

        </Form.Select>
      </div>
    </>
  );
};

export default LanguageSwitcherComponent;
