import React, { useState } from 'react';
import i18n from "../../i18n";
import { useAuth } from "../../context/auth.context";
import { Button, Card, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faCircleXmark, faCircleCheck } from "@fortawesome/pro-light-svg-icons";
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { setLoading, updateSettings } from '../../actions/auth.action'
import { randomId, getResetToken } from '../../services/utils';
import apiService from '../../services/api';

const ResetPasswordFormComponent: React.FC = () => {

  const { t } = i18n;
  const { loading, settings, dispatch } = useAuth();
  const [formValidated, setFormValidated] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const passwordTracker = {
    uppercase: newPassword.match(/[A-Z]/g),
    lowercase: newPassword.match(/[a-z]/g),
    number: newPassword.match(/[0-9]/g),
    specialChar: newPassword.match(/[#?!@$%^&*-]/g),
    minChars: newPassword.length >= 10,
    maxChars: newPassword.length <= 32,
  }

  const passwordStrength = Object.values(passwordTracker).filter(value => value).length;

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {

    const api = new apiService()

    setFormValidated(false);
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false || passwordStrength < 6 || newPassword !== confirmPassword) {
      event.stopPropagation();
      setFormValidated(false);
      return;
    }


    try {

      dispatch(setLoading(true));
      dispatch(updateSettings({ ...settings, error: '' }))

      // Make API call to reset pw
      const resp = await api.resetPassword({ token: getResetToken(), new_password: newPassword, confirm_password: confirmPassword })

      if (resp.status === 200) {
        window.location.reload();
      } else {
        throw new Error();
      }

    } catch (error: any) {

      let validResetToken = true;
      if (error.response.status === 401) {
        validResetToken = false
      }

      if (error.response && error.response.data && error.response.data.message) {
        dispatch(updateSettings({ ...settings, error: error.response.data.message, validResetToken: validResetToken }))
      } else {
        dispatch(updateSettings({ error: t('_system_error') }))
      }
      dispatch(setLoading(false));

    }

    setFormValidated(true);
  }

  return (
    <>

      <Card.Body>
        <Card.Title className="text-start"><h1>{t('_reset_password.title')}</h1></Card.Title>
        <Card.Text className="text-start">{t('_reset_password.description')}</Card.Text>

        <Form noValidate validated={formValidated} onSubmit={handleSubmit}>
          <InputGroup className="mb-3">
            <InputGroup.Text><FontAwesomeIcon icon={faLock} /></InputGroup.Text>
            <Form.Control
              placeholder={t(['new_password'])}
              aria-label="password"
              required
              type="password"
              onChange={(e) => setNewPassword(e.target.value)}
              id={(randomId())}
              disabled={loading}
              isInvalid={((newPassword !== '') && (passwordStrength < 6))}
              isValid={passwordStrength === 6}

            />
          </InputGroup>

          <ul className='list-group-flush text-start password-strength-list'>
            <li className="list-group-item"><FontAwesomeIcon icon={passwordTracker.lowercase ? faCircleCheck : faCircleXmark} size='lg' color={passwordTracker.lowercase ? 'green' : 'red'} />{t('_password_strength.lowercase')}</li>
            <li className="list-group-item"><FontAwesomeIcon icon={passwordTracker.uppercase ? faCircleCheck : faCircleXmark} size='lg' color={passwordTracker.uppercase ? 'green' : 'red'} />{t('_password_strength.uppercase')}</li>
            <li className="list-group-item"><FontAwesomeIcon icon={passwordTracker.number ? faCircleCheck : faCircleXmark} size='lg' color={passwordTracker.number ? 'green' : 'red'} />{t('_password_strength.number')}</li>
            <li className="list-group-item"><FontAwesomeIcon icon={passwordTracker.specialChar ? faCircleCheck : faCircleXmark} size='lg' color={passwordTracker.specialChar ? 'green' : 'red'} />{t('_password_strength.special_char')}</li>
            <li className="list-group-item"><FontAwesomeIcon icon={passwordTracker.minChars ? faCircleCheck : faCircleXmark} size='lg' color={passwordTracker.minChars ? 'green' : 'red'} />{t('_password_strength.min_char')}</li>
            <li className="list-group-item"><FontAwesomeIcon icon={passwordTracker.maxChars ? faCircleCheck : faCircleXmark} size='lg' color={passwordTracker.maxChars ? 'green' : 'red'} />{t('_password_strength.max_char')}</li>
          </ul>

          <InputGroup className="mb-3">
            <InputGroup.Text><FontAwesomeIcon icon={faLock} /></InputGroup.Text>
            <Form.Control
              placeholder={t('confirm_password')}
              aria-label="confirm_password"
              required
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              id={(randomId())}
              disabled={loading}
              isInvalid={newPassword !== confirmPassword}
              isValid={confirmPassword !== '' && newPassword === confirmPassword}
            />
            <Form.Control.Feedback type="invalid">
              {confirmPassword ? `${t('confirm_password_not_match', { field: t('password') })}` : t('field_cannot_be_blank', { field: t('confirm_password') })}
            </Form.Control.Feedback>
          </InputGroup>

          <Button variant="primary" type="submit" disabled={loading}>
            {loading && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
            {t('continue')}
          </Button>
        </Form>
      </Card.Body>
    </>
  );
};

export default ResetPasswordFormComponent;
