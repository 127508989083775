import React from 'react';

import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';

const LoadingComponent: React.FC = () => {
  return (
    <>
      <Card.Body>
        <FontAwesomeIcon icon={faSpinner} className="fa-spin mt-4 mb-4" size='3x' color='#1F94FC' />
      </Card.Body>
    </>
  );
};

export default LoadingComponent;
