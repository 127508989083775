import { Card } from "react-bootstrap"
import i18n from "../i18n"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth.context";
import { logoutSuccess } from '../actions/auth.action'
import useCustomCookies from "../hooks/useCustomCookies.hooks";
import useDocument from '../hooks/useDocument.hooks'

function LogoutSuccessPage() {

    const { t } = i18n;

    useDocument({title: t('_page_title.logout')})

    const navigate = useNavigate();
    const { dispatch } = useAuth();
    const {deleteAuthCookies} = useCustomCookies()



    useEffect(() => {

        const logout = async () => {
            deleteAuthCookies()
            dispatch(logoutSuccess())
            navigate("/auth");
        }

        logout()
    })

    
    return (
        <>
            <Card.Body>
                <Card.Title className="text-start"><h1>{t('_logout.title')}</h1></Card.Title>
                <Card.Text className="text-start">{t('_logout.description')}</Card.Text>
            </Card.Body>
        </>
    )
}

export default LogoutSuccessPage