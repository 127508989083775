import React from 'react'
import { Alert } from 'react-bootstrap';
import i18n from "../i18n"

const CookieBannerComponent: React.FC = () => {

    const { t } = i18n

    const today = new Date().setHours(0, 0, 0, 0);
    const endDate = new Date('2024-08-04').setHours(0, 0, 0, 0);

    if (today > endDate) {
        return (<></>)
    }

    return (
        <>
            <Alert variant={'warning'} className='text-center m-auto mt-3 mb-3' style={{ width: 'fit-content' }}>
                <strong>{t('_having_trouble_logging')}</strong>
                <br />
                {`${t('_clear_browser_cookie')}, ${t('click')} `}
                <a rel="noreferrer" href={`${t('_clear_cookie_link')}`} target="_blank">{t('here')}</a>{t('instructions_lang')}.
            </Alert>
        </>
    )

}

export default CookieBannerComponent