import React, { useState, useRef } from 'react';
import { useAuth } from '../../context/auth.context';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { setLoading, updateSettings, updateCode, updateRememberDevice } from '../../actions/auth.action'
import i18n from '../../i18n';
import { randomId } from '../../services/utils';
import apiService from '../../services/api';
import { Link } from "react-router-dom";
import { login } from '../../services/auth.service';
import { Trans } from 'react-i18next';


const PIN_LENGTH = 6;

const MfaStepComponent: React.FC = () => {

    const api = new apiService();
    const { t } = i18n;
    const { email, password, settings, loading, dispatch } = useAuth();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_formValidated, setFormValidated] = useState(false);

    const [pin, setPin] = useState<string[]>(Array(PIN_LENGTH).fill(''));
    const [rememberDevice, setRememberDevice] = useState<boolean>(false);
    const [errors, setErrors] = useState<string | null>(null);
    const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(PIN_LENGTH).fill(null));
    const formRef = useRef<HTMLFormElement>(null);

    // Handle API call for resending the code
    const handleResendCode = async () => {
        try {
            dispatch(setLoading(true));
            dispatch(updateSettings({ ...settings, error: '' }));
            resetPin();

            // Make API call to validate email
            const resp = await api.validateCredentials({ email, password: password || '' });

            if (resp.status === 200) {
                dispatch(updateSettings({ ...settings, error: '', passwordConfirmed: true }));
            } else {
                throw new Error();
            }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch(updateSettings({ ...settings, error: error.response.data.message }));
            } else {
                dispatch(updateSettings({ error: t('_system_error') }));
            }
        } finally {
            dispatch(setLoading(false));
            setTimeout(() => focusOnInputNumber(0), 0);
        }
    };


    const focusOnInputNumber = (index: number) => {
        if (index < 0 || index >= PIN_LENGTH) {
            return;
        }

        inputRefs.current[index]?.focus();
    }

    const resetPin = () => {
        const emptyPin = Array(PIN_LENGTH).fill('');
        setPin(emptyPin);
        setErrors(null);
        focusOnInputNumber(0);
    }

    // Handle input change for each digit
    const handleInputChange = async (index: number, value: string) => {
        if (value.length === PIN_LENGTH) {
            handleCopyPasteCode(value);
            focusOnInputNumber(PIN_LENGTH - 1);
            return;
        }

        if (!value.match(/[0-9]/)) {
            return;
        }

        const newPin = [...pin];
        newPin[index] = value.slice(-1);
        setPin(newPin);

        // Move focus to the next input if digit is entered
        if (newPin[index] && index < PIN_LENGTH - 1) {
            focusOnInputNumber(index + 1);
        } else if (index === PIN_LENGTH - 1 && !errors) {
            handleSubmitClick();
        }
    };

    const handleCopyPasteCode = (value: string) => {
        const newPin = [...pin];
        value.split('').forEach((digit, i) => {
            if (i < pin.length) {
                newPin[i] = digit;
            }
        });
        setPin(newPin);
    }

    // navigate between input fields on key press
    const handleKeyDown = (event: any, index: number) => {
        switch (event.key) {
            case 'Backspace':
                const newPin = [...pin];
                newPin[index] = '';
                setPin(newPin);
                focusOnInputNumber(index - 1);
                break;
            case 'ArrowRight':
                event.preventDefault();
                focusOnInputNumber(index + 1);
                break;
            case ' ': // pressing 'space'
                event.preventDefault();
                focusOnInputNumber(index + 1);
                break;
            case 'ArrowLeft':
                event.preventDefault();
                focusOnInputNumber(index - 1);
                break;
            case 'Enter':
                event.preventDefault();
                handleSubmitClick();
                break;
            default:
                break;
        }
    };

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        setFormValidated(true);
        const form = event.currentTarget;

        if (form.checkValidity() && pin.join('').length === PIN_LENGTH) {
            try {

                dispatch(setLoading(true));
                const mfaAuthCode = pin.join('');
                dispatch(updateCode(mfaAuthCode));
                dispatch(updateRememberDevice(rememberDevice));
                dispatch(updateSettings({ ...settings, error: '', globalLoadingState: true }));
                await login(email, password!, mfaAuthCode, rememberDevice);

            } catch (error: any) {

                const errorMessage = error.response?.data?.message || t('_system_error');
                dispatch(updateSettings({ ...settings, error: errorMessage, globalLoadingState: false }));
                setErrors(errorMessage);

            } finally {
                dispatch(setLoading(false));
            }

        } else {
            event.stopPropagation();
        }
    };

    const handleSubmitClick = () => {
        if (pin.join('').length === PIN_LENGTH && !errors) {
            formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
    };

    return (
        <>
            <Form noValidate onSubmit={handleSubmit} ref={formRef}>
                <p className="text-start font-weight-normal small mb-2">
                    <Trans i18nKey="enter_2fa_code" />
                </p>
                <div className='d-flex justify-content-around align-items-center gap-2 mb-4'>
                    {pin.map((digit, index) => (
                        <Form.Control
                            key={index}
                            aria-label="code"
                            required
                            type="tel"
                            onChange={(e) => handleInputChange(index, e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            id={(randomId())}
                            disabled={loading}
                            readOnly={loading}
                            autoFocus={index === 0}
                            ref={(ref: any) => (inputRefs.current[index] = ref)}
                            value={digit}
                            style={{ height: '3rem' }}
                            className='text-center no-cursor'
                        />
                    ))}
                </div>
                <Form.Control.Feedback type="invalid">
                    {errors ? errors : t('field_cannot_be_blank', { field: t('code') })}
                </Form.Control.Feedback>
                <div className='text-end help-link'>
                    {t('reqeust_resend_code')}
                    <Button variant="link" disabled={loading} className='p-0 m-0 ms-2' onClick={handleResendCode}>
                        {t('resend')}
                    </Button>
                </div>

                {settings.daysSkipping2FA && settings.daysSkipping2FA > 0 &&
                    (<div className="d-flex flex-row justify-content-center align-content-center mt-3">
                        <Form.Check
                            inline
                            type='checkbox'
                            checked={rememberDevice}
                            onChange={(e) => setRememberDevice(e.target.checked)}
                            id={`id_remember-device-checkbox`}
                            className="remember-device-checkbox"
                        />
                        <p className="text-start mb-0">
                            <Trans i18nKey="remember_device" values={{days: settings.daysSkipping2FA}} />
                        </p>
                    </div>)
                }

                <Button variant="primary" type="submit" disabled={loading || !pin.every(digit => digit !== '')} className="mt-3 fixed-width-submit-button">
                    <div className="notranslate d-flex align-items-center justify-content-center" translate="no">
                        {loading && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
                        {t('continue')}
                    </div>
                </Button>
                <div className='text-center mt-2 help-link'>
                    <Link to="/auth/" className='text-decoration-none' onClick={() => dispatch(updateSettings({ ...settings, error: '', passwordConfirmed: false }))}>
                        {t('return_to_sign_in')}
                    </Link>
                </div>
            </Form>
        </>
    );
};

export default MfaStepComponent;