import { AxiosInstance } from "axios";

export default function initInterceptors(httpService: AxiosInstance) {
    httpService.interceptors.request.use((request) => {
        return request;
    });

    httpService.interceptors.response.use(
        (res) => res,
        (error) => {
            return Promise.reject(error);
        }
    );
}